<template>
    <div v-if="isLoaded" class="min-h-screen flex items-center justify-center py-5">
        <div class="w-full max-w-7xl overflow-hidden mx-4 pb-4">
            <div class="flex flex-col space-y-5">
                <div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
                    <AdminDataCard class="cursor-pointer"
                        v-for="c in cardItems"
                        :icon="c.icon"
                        :title="c.title"
                        :total="c.total"
                        :growthRate="c.growthRate"
                        :link="c.link"
                    ></AdminDataCard>
                </div>

                <AdminChartMain />

                <AdminActivity :activityData="latestActivity" />
            </div>
        </div>
    </div>
</template>

<script>
import AdminDataCard from '@/components/marketplace/admin/DataCard.vue'
import AdminActivity from '@/components/marketplace/admin/Activity.vue'
import AdminChartMain from '@/components/marketplace/admin/ChartMain.vue'

import adminService from '@/services/marketplace/admin.service';

export default {
    name: 'AdminDashboard',
    inject: ['toggleLoading'],
    components: {
        AdminDataCard,
        AdminActivity,
        AdminChartMain,
    },
    data() {
        return {
            isLoaded: false,
            cardItems: [
                {
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" /></svg>`,
                    title: 'Total companies',
                    total: '0',
                    growthRate: 0.43,
                    link: '/dashboard/companies'
                },
                {
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" /></svg>`,
                    title: 'Total contractors',
                    total: '0',
                    growthRate: 4.35,
                    link: '/dashboard/contractors'
                },
                {
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" /></svg>`,
                    title: 'Total jobs',
                    total: '0',
                    growthRate: 0.43,
                    link: '/dashboard/jobs'
                },
                {
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" /></svg>`,
                    title: 'Total applications',
                    total: '0',
                    growthRate: -5.95,
                    link: '/dashboard/applications'
                }
            ],
            latestActivity: [],
        }
    },
    methods: {
        async fetchStats() {
            this.toggleLoading(true)
            adminService.getStats()
            .then(response => {
                this.cardItems[0].total = response.data.total_companies
                this.cardItems[1].total = response.data.total_contractors
                this.cardItems[2].total = response.data.total_jobs
                this.cardItems[3].total = response.data.total_applications

                this.isLoaded = true
            })
            .catch((error) => {
                this.$router.push('/dashboard/auth');
            })
            .finally(() => {
              this.toggleLoading(false);
            })
        },
        async fetchLatestActivity() {
            adminService.getLatestActivity()
            .then(response => {
                this.latestActivity = response.data;
            })
            .catch((error) => {
            //   this.$root.notify('Something went wrong while fetching available jobs, please try again', 'error');
            })
            .finally(() => {
            //   this.getApplications()
            //   this.loading = false
            //   this.toggleLoading(false);
            })
        },
    },
    created() {
        this.fetchStats()
        this.fetchLatestActivity()
    }
}
</script>