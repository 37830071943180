<template>

      <div class="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
          <h2 class="text-title-md2 font-semibold text-black dark:text-white">Contractors</h2>
          <nav>
              <ol class="hidden flex items-center gap-2">
                  <li><a href="/" class="font-medium"> Dashboard / </a></li>
                  <li class="font-medium text-primary">Pro Tables</li>
              </ol>
          </nav>
      </div>

      <div class="flex flex-col gap-10">
          <div class="overflow-hidden rounded-[10px]">
              <div class="max-w-full overflow-x-auto">
                  <div class="min-w-[1170px]">
                      <div class="grid grid-cols-12 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
                          <div class="col-span-3">
                              <h5 class="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
                          </div>

                          <div class="col-span-3">
                              <h5 class="font-medium text-[#637381] dark:text-bodydark">EMAIL</h5>
                          </div>

                          <div class="col-span-1">
                              <h5 class="font-medium text-[#637381] dark:text-bodydark">INDUSTRY</h5>
                          </div>

                          <div class="col-span-2">
                              <h5 class="font-medium text-[#637381] dark:text-bodydark">ROLE</h5>
                          </div>

                          <div class="col-span-2">
                              <h5 class="font-medium text-[#637381] dark:text-bodydark">REGISTRATION</h5>
                          </div>

                          <div class="col-span-1">
                              <h5 class="font-medium text-[#637381] dark:text-bodydark">ACTION</h5>
                          </div>
                      </div>

                      <div class="bg-white dark:bg-boxdark">
                          <div v-for="contractor in contractors" :key="contractor.id" class="grid grid-cols-12 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11 hover:bg-sky-100">
                              <div class="col-span-3 flex items-center">
                                  <div class="h-16 w-full max-w-16 flex items-center justify-center rounded-full border-[3px] border-stroke dark:border-strokedark">
                                      <img class="rounded-full object-cover border" :src="getImage(contractor.photo_hash)">
                                  </div>
                                  <p class="text-[#637381] dark:text-bodydark ml-4">{{ contractor.first_name }} {{ contractor.last_name }}</p>
                              </div>

                              <div class="col-span-3 flex items-center">
                                  <p class="text-[#637381] dark:text-bodydark"><a class="text-blue-300" :href="`mailto:${contractor.email}`">{{ contractor.email }}</a></p>
                              </div>

                              <div class="col-span-1 flex items-center">
                                  <p class="text-[#637381] dark:text-bodydark">{{ contractor.industry }}</p>
                              </div>

                              <div class="col-span-2 flex items-center">
                                  <p class="text-[#637381] dark:text-bodydark">{{ contractor.role }}</p>
                              </div>

                              <div class="col-span-2 flex items-center">
                                  <p class="text-[#637381] dark:text-bodydark" title="testing">{{ formattedPostedDate(contractor.creation_date) }}</p>
                              </div>

                              <div class="col-span-1 flex space-x-2 items-center ">
                                  <router-link :to="`/dashboard/contractors/${contractor.id}`">
                                      <button class="inline-flex items-center p-2 rounded-full bg-blue-700 hover:bg-blue-600 text-white focus:outline-none" title="View profile">
                                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-1.274 4.057-5.065 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                                          </svg>
                                      </button>
                                  </router-link>

                                  <button @click="getResume(contractor.id, contractor.first_name + contractor.last_name)" class="inline-flex items-center p-2 rounded-full bg-blue-700 hover:bg-blue-600 text-white focus:outline-none" title="Download resume">
                                    <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>',
                                    </svg>
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>


</template>

<script>
import genericService from '@/services/marketplace/generic.service';
import adminService from '@/services/marketplace/admin.service';
export default {
  name: 'AdminTableCompany',
  inject: ['toggleLoading'],
  props: ['contractors'],
  components: {},
  data() {
      return {

      }
  },
  methods: {
        getResume(contractorId, applicantName) {
            this.toggleLoading(true);
            adminService.getContractorResume(contractorId)
            .then(response => {
                const contentType = response.headers.get('content-type');
                let extension;
                switch(contentType) {
                    case 'application/pdf':
                        extension = 'pdf'
                        break;
                    case 'application/msword':
                        extension = 'doc'
                        break;
                    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                        extension = 'docx'
                        break
                }

                if (response.status === 200 && (contentType === 'application/pdf' ||
                    contentType === 'application/msword' ||
                    contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {

                    const outputFileName = `Screened-${contractorId}-${applicantName}.${extension}`;
                    const myBlob = response.data;  // Blob data from the response
                    const url = window.URL.createObjectURL(myBlob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', outputFileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link); // Clean up
                    window.URL.revokeObjectURL(url); // Revoke the URL after use
                } else {
                    this.$root.notify('Your resume could not be retrieved at this time. Please try again later..', 'error');
                }
            })
            .catch((error) => {
                // Redirect if 401
            })
            .finally(() => {
              this.toggleLoading(false);
            })
        },
        formattedPostedDate(creationDateString) {
            const creationDate = new Date(creationDateString);
            const now = new Date();
            const diffInMs = now - creationDate;
            const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert ms to hours

            if (diffInHours < 24) {
                return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
            }

            const diffInDays = Math.floor(diffInHours / 24);
            if (diffInDays === 1) {
                return "1 day ago";
            }

            return `${diffInDays} days ago`;
        },
        getImage(hash) {
            return genericService.getProfilePictureLink(hash)
        }
  },
  created() {
      console.log(this.contractors)
  }
}
</script>