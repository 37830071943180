<template></template>

<script>
import adminService from '@/services/marketplace/admin.service';

export default {
    name: 'AdminAuthenticationVerify',
    inject: ['toggleLoading'],
    async mounted() {
        this.toggleLoading(true)

        let token = this.$route.params.token

        if (token) {
            adminService.verifyAuthenticationToken(token)
            .then(response => {
                this.$router.push('/dashboard');
            })
            .catch((error) => {
                this.$root.notify('Unable to verify your authentication request. Please request a new link and try again', 'error');
                this.$router.push('/dashboard/auth');
            })
            .finally(() => {
                this.toggleLoading(false)
            })
        } else {
            this.$router.push('/dashboard/auth');
        }
    }
}
</script>