<template>
    <header class="bg-white drop-shadow-md">
      <nav class="flex justify-between items-center text-white p-6 xl:container xl:mx-auto">
        <!-- Logo -->
        <div class="flex">
          <a href="/dashboard" class="logo">
            <img class="w-1/2 ml-2" src="@/assets/logo.png" alt="Screened logo">
          </a>
        </div>
  
        <!-- Desktop Menu -->
        <div class="hidden md:flex space-x-6 items-center">
          <router-link to="/dashboard/companies" class="flex items-center text-gray-500 hover:text-sky-500">
            <span class="ml-1">Companies</span>
          </router-link>
          <router-link to="/dashboard/contractors" class="flex items-center text-gray-500 hover:text-sky-500">
            <span class="ml-1">Contractors</span>
          </router-link>

        </div>
  
        <!-- Mobile Menu -->
        <div class="md:hidden">
          <button
            @click="toggleMobileMenu"
            class="flex items-center p-2 bg-gray-700 text-white rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
            Menu
          </button>
          <transition name="fade">
            <div
              v-if="mobileMenuOpen"
              class="absolute left-0 top-full bg-white text-gray-700 shadow-md w-full border-t border-gray-300"
            >
              <ul>
                <li class="list-none text-xl py-4">
                  <router-link
                    to="/dashboard/company"
                    class="block px-4 py-2 hover:bg-gray-100"
                  >
                    Companies
                  </router-link>
                </li>
                <li class="list-none text-xl py-4">
                  <router-link
                    to="/dashboard/contractors"
                    class="block px-4 py-2 hover:bg-gray-100"
                  >
                    Contractors
                  </router-link>
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </nav>
    </header>
  </template>
  
  <script>
  export default {
    name: "AdminHeader",
    data() {
      return {
        mobileMenuOpen: false,
        userMenuOpen: false,
      };
    },
    methods: {
      toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
      },
      toggleUserMenu() {
        this.userMenuOpen = !this.userMenuOpen;
      },
    },
    computed: {
    contractorName() {
        return this.$store.state.contractorName || 'Guest'; // Default fallback if not set
    },
  },
  };
  </script>
  
  <style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  </style>
  