<template>
    <div class="flex mt-5 mx-auto max-w-7xl px-5">
        <template v-if="loading">
            <div class="flex flex-col space-y-5 w-full">
                <div class="p-6 bg-sky-500 text-white rounded-lg">
                    <h2 class="text-2xl leading-none tracking-tight lg:text-3xl text-white">Applied jobs</h2>
                </div>
                <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <JobCardLoading></JobCardLoading>
                    <JobCardLoading></JobCardLoading>
                    <JobCardLoading></JobCardLoading>
                    <JobCardLoading></JobCardLoading>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="applications.length == 0" class="flex flex-col w-full items-center py-12 bg-white rounded-lg">
                <h3>You have not applied to any job yet</h3>
                <p>Start applying for jobs today and track your applications here</p>
            </div>

            <div v-else class="flex flex-col space-y-5 w-full">
                <div class="p-6 bg-sky-500 text-white rounded-lg">
                    <h2 class="text-2xl leading-none tracking-tight lg:text-3xl text-white">Applied jobs</h2>
                </div>
                <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <JobCard
                        v-for="a in applications"
                        :key="a.id"
                        :vJobId="a.job_id"
                        :vSlug="a.job_slug"
                        :vTitle="a.job_title"
                        :vCompany="a.company_name"
                        :vLocation="a.job_location"
                        :vIndustry="a.job_industry"
                        :vRole="a.job_role"
                        :vDate="a.job_date"
                        :vAppDate="a.application_date"
                        :vRateCategory="a.rate_category"
                        :vRate="a.job_rate"
                        :vRateType="a.job_rate_type"
                        :vStartDate="a.job_start">
                    </JobCard>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import contractorService from '@/services/marketplace/contractor.service'
import JobCard from '@/components/marketplace/generic/JobCard'
import JobCardLoading from '@/components/marketplace/generic/JobCardLoading'

export default {
    name: 'ContractorAppliedJobs',
    inject: ['toggleLoading'],
    components: {
        JobCard,
        JobCardLoading
    },
    data() {
        return {
            loading: true,
            applications: []
        };
    },
    methods: {
        getApplications() {
            contractorService.getApplications()
            .then(response => {
                this.applications = response.data;
                this.loading = false
            })
        }
    },
    created() {
        this.getApplications()
    }
}
</script>