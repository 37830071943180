<template>
    <div class="container mx-auto p-6">

        <div class="p-6 bg-sky-500 text-white rounded-lg mb-6">
            <h2 class="text-2xl leading-none tracking-tight lg:text-3xl text-white">{{ jobData.title }}</h2>
        </div>

        <!-- Tabbed Job Details View -->
        <div class="bg-white shadow rounded-lg p-6">
            <!-- Job Title -->
            <div class="mb-6">
                <div class="flex flex-col leading-tight">
                    <p class="text-gray-700">{{ jobData.company_name }}</p>
                </div>
                <p class="mt-1">{{ jobData.work_location }}</p>
                <p class="text-sm text-gray-500 mt-2">{{ formattedPostedDate  }}</p>
                <p class="text-sm text-gray-500">Job ID: <span class="hover:cursor-pointer" @click="copyJobIdToClipboard">#{{ jobData.slug }}</span></p>
            </div>
  
            <!-- Tabs -->
            <div class="flex border-b border-gray-200 mb-4">
                <button
                  v-for="tab in tabs"
                  :key="tab.id"
                  class="py-2 px-4 text-sm font-medium focus:outline-none"
                  :class="{
                    'border-b-2 border-sky-500 text-sky-600': currentTab === tab.id,
                    'text-gray-500 hover:text-gray-700': currentTab !== tab.id
                  }"
                  @click="currentTab = tab.id"
                >
                  {{ tab.name }}
                </button>
            </div>
  
        <!-- Tab Content -->
        <div>
            <!-- Experience Tab -->
            <div v-if="currentTab === 'job'">
                <div class="py-3">
                    <div class="flex items-center space-x-2 relative">
                        <span class="block text-gray-500">Job description</span>
                            <div class="group relative hover:cursor-pointer" @click="switchDecriptionEditing">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-sky-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                </svg>
                                <!-- Tooltip -->
                                <span class="absolute left-1/2 transform -translate-x-1/2 -top-12 text-xs font-medium text-white bg-gray-800 px-2 py-1 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                  Edit job description
                                </span>
                            </div>
                        </div>

                        <p class="text-gray-600 mt-2" v-html="jobData.description_rendered"></p>
                    </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-3">
                    <!-- Work type -->
                    <div>
                        <span class="block text-gray-500">Work type</span>
                        <p class="text-gray-800">{{ formatDatabaseString(jobData.work_type) }}</p>
                    </div>
                    <!-- Contract length (optional) -->
                    <div :class="jobData.work_type !== 'contract' ? 'invisible' : ''">
                        <span class="block text-gray-500">Length of contract</span>
                        <p class="text-gray-800">
                            {{ jobData.contract_length || 'Not applicable' }}
                        </p>
                    </div>
                    <!-- Start date -->
                    <div>
                        <span class="block text-gray-500">Start date</span>
                        <p class="text-gray-800">{{ formatDate(jobData.contract_start_date) }}</p>
                    </div>
                    <!-- Rate and rate type -->
                    <div>
                        <span class="block text-gray-500">Rate</span>

                        <p v-if="jobData && jobData.rate_category && jobData.rate_category !== 'custom_rate'">
                            {{ jobData.rate_category.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()).replace(/\s*Rate\s*/gi, '').trim() }} Rate
                        </p>
                        <p v-else class="text-gray-800">${{ jobData.rate }} / {{ jobData.rate_type }}</p>
                    </div>
                </div>

                <div class="mt-6">
                    <span class="block text-gray-500">Work arrangement</span>
                    <p class="text-gray-800" :class="jobData.work_arrangement == 'wfh' ? 'uppercase' : ''">{{ $filters.screenedWorkArrangement(jobData.work_arrangement) }}</p>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-3">
                    <div class="mt-6" v-if="jobData.roster">
                        <span class="block text-gray-500">Roster</span>
                        <p class="text-gray-800">{{ jobData.roster }}</p>
                    </div>

                    <div class="mt-6" v-if="jobData.hours">
                        <span class="block text-gray-500">Hours</span>
                        <p class="text-gray-800">{{ jobData.hours }}</p>
                    </div>
                </div>

                <template v-if="jobData.is_project">
                    <div class="mt-6">
                        <span class="block text-gray-500">Project description</span>
                        <p class="text-gray-800">{{ jobData.project_description }}</p>
                    </div>

                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-3">
                        <div class="mt-6" v-if="jobData.project_timeframe">
                            <span class="block text-gray-500">Project timeframe</span>
                            <p class="text-gray-800">{{ jobData.project_timeframe }}</p>
                        </div>

                        <div class="mt-6" v-if="jobData.project_stage">
                            <span class="block text-gray-500">Project stage</span>
                            <p class="text-gray-800">{{ jobData.project_stage }}</p>
                        </div>

                        <div class="mt-6" v-if="jobData.project_team_size">
                            <span class="block text-gray-500">Project team size</span>
                            <p class="text-gray-800">{{ jobData.project_team_size }}</p>
                        </div>

                        <div class="mt-6" v-if="jobData.project_budget">
                            <span class="block text-gray-500">Project budget</span>
                            <p class="text-gray-800">{{ jobData.project_budget }}</p>
                        </div>
                    </div>
                </template>
            </div>
  
            <!-- Conditions Tab -->
            <div v-if="currentTab === 'conditions'" class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                <span class="block text-gray-500">Primary Work Location</span>
                <p class="text-gray-800">{{ jobData.work_location }}</p>
                </div>
                <div>
                <span class="block text-gray-500">Length of contract</span>
                <p class="text-gray-800">{{ jobData.contract_length  }}</p>
                </div>
                <div>
                <span class="block text-gray-500">Start date</span>
                <p class="text-gray-800">{{ jobData.contract_start_date }}</p>
                </div>
                <div>
                <span class="block text-gray-500">Rate</span>
                <p class="text-gray-800">{{ jobData.rate }}</p>
                </div>
                <div>
                <span class="block text-gray-500">Work Arrangement</span>
                <p class="text-gray-800">{{ jobData.work_arrangement }}</p>
                </div>
                <div>
                <span class="block text-gray-500">Roster</span>
                <p class="text-gray-800">{{ jobData.roster }}</p>
                </div>
                <div>
                <span class="block text-gray-500">Hours</span>
                <p class="text-gray-800">{{ jobData.hours }}</p>
                </div>
            </div>

            <!-- Experience Tab -->
            <div v-if="currentTab === 'project-details'" class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div class="md:col-span-2">
                <span class="block text-gray-500">Project Description</span>
                <p class="text-gray-800">{{ jobData.project_description }}</p>
                </div>
                <div>
                <span class="block text-gray-500">Project Timeframe</span>
                <p class="text-gray-800">{{ jobData.project_timeframe }}</p>
                </div>
                <div>
                <span class="block text-gray-500">Current Project Stage</span>
                <p class="text-gray-800">{{ jobData.project_stage }}</p>
                </div>
                <div>
                <span class="block text-gray-500">Project Team Size</span>
                <p class="text-gray-800">{{ jobData.project_team_size}}</p>
                </div>
                <div>
                <span class="block text-gray-500">Project Budget</span>
                <p class="text-gray-800">{{ jobData.project_budget }}</p>
                </div>
            </div>
  
          <!-- Applicants Tab -->
          <div v-if="currentTab === 'applicants'">
              <h3 class="text-2xl font-bold text-gray-800 mb-6">Applicants</h3>
              <div v-show="applicants.length === 0">Currently no applicants have applied for this job posting. Please check back later or consider promoting your listing to attract more candidates.</div>
              <div class="space-y-6">
                  <div
                      v-for="applicant in applicants"
                      :key="applicant.application_id"
                      class="flex items-center bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition border border-gray-300 space-x-6"
                  >
                  <!-- Profile Picture -->
                  <img
                    :src="getImage(applicant.photo_hash)"
                    alt="Profile Picture"
                    class="w-16 h-16 rounded-full object-cover border border-gray-300"
                  />

                  <!-- Candidate Details -->
                  <div class="flex-1 space-y-2">
                  <!-- Name -->
                  <p class="text-lg font-semibold text-gray-900 tracking-wide">{{ applicant.first_name }} {{ applicant.last_name }}</p>
                  <!-- Preferred Role -->
                  <p class="text-sm text-gray-700 italic">{{ applicant.preferred_role }}</p>
                  <!-- Additional Information -->
                  <div class="grid grid-cols-2 gap-y-2 text-sm">
                    <div>
                      <span class="font-medium text-gray-800">Location:</span>
                      <p class="text-gray-600">{{ applicant.suburb }}, {{ applicant.state }}</p>
                    </div>
                    <div>
                      <span class="font-medium text-gray-800">Availability:</span>
                      <p class="text-gray-600">{{ $filters.screenedAvailability(applicant.availability) }}</p>
                    </div>
                    <div v-if="applicant.rate">
                      <span class="font-medium text-gray-800">Rate:</span>
                      <p class="text-gray-600">${{ applicant.rate }} / {{  applicant.rate_type }}</p>
                    </div>
                  </div>
                </div>

                <!-- Action Buttons -->
                <div class="flex flex-col space-y-2">
                  <!-- View Profile Button -->
                  <router-link
                    tag="a"
                    target="_blank"
                    :to="`/company/view-job/${jobData.uuid}/applicant/${applicant.profile_hash}`"
                    class="px-5 py-2 bg-sky-500 text-white text-sm font-medium rounded-lg shadow hover:shadow-lg transition text-center"
                  >
                    View Profile
                  </router-link>

                  <!-- Download Resume Button -->
                  <button
                    @click="downloadResume(jobData.uuid, jobData.slug, applicant.profile_hash, applicant.first_name + applicant.last_name)"
                    class="px-5 py-2 bg-gray-200 text-gray-700 text-sm font-medium rounded-lg shadow hover:bg-gray-300 hover:shadow-lg transition text-center"
                  >
                    Download Resume
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import companyService from '@/services/marketplace/company.service';
import genericService from '@/services/marketplace/generic.service';
import QuillEditor from '@/components/marketplace/generic/QuillEditor.vue';

export default {
    name: 'ViewJob',
    components: {
      QuillEditor,
    },
    inject: ['toggleLoading'],
    data() {
      return {
        jobData: [],
        applicants: [],
        currentTab: 'job',
        tabs: [
          { id: 'job', name: 'Job' },
          { id: 'applicants', name: 'Applicants' }
        ],
        isEditingDescription: false,
      };
    },
    methods: {
      async fetchJob() {
        this.toggleLoading(true);
        try {
          companyService.getJob(this.$route.params.jobId)
            .then(response => {
              this.jobData = response.data;
              this.toggleLoading(false);
            })
            .catch(error => {
              this.toggleLoading(false);
              console.error('Error fetching jobs:', error);
            });
        } catch (error) {
          this.toggleLoading(false);
          console.error('Error fetching jobs:', error);
        }
      },
      fetchApplicants() {
         companyService.getApplications(this.$route.params.jobId)
         .then(response => {
              this.applicants = response.data
          })
      },
      editJob(jobId) {
        // Navigate to job editing page or open an editing form (implement as needed)
        this.$router.push(`/edit-job/${jobId}`);
      },
      formatDate(timestamp) {
        if (!timestamp) return '';
        const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      },
      translatedStatus(status) {
        switch (status) {
          case 0:
            return 'Pending';
          case 1:
            return 'Approved';
          case 2:
            return 'Rejected';
          default:
            return 'Unknown';
        }
      },
      getImage(hash) {
          return genericService.getProfilePictureLink(hash)
      },
      downloadResume(jobId, jobSlug, applicantId, applicantName) {
          this.toggleLoading(true);
          companyService.downloadApplicantResume(jobId, applicantId)
            .then(response => {
                const contentType = response.headers.get('content-type');
                let extension;
                switch(contentType) {
                    case 'application/pdf':
                        extension = 'pdf'
                        break;
                    case 'application/msword':
                        extension = 'doc'
                        break;
                    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                        extension = 'docx'
                        break
                }

                if (response.status === 200 && (contentType === 'application/pdf' ||
                    contentType === 'application/msword' ||
                    contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {

                    const outputFileName = `Screened-${jobSlug}-${applicantName}.${extension}`;
                    const myBlob = response.data;  // Blob data from the response
                    const url = window.URL.createObjectURL(myBlob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', outputFileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link); // Clean up
                    window.URL.revokeObjectURL(url); // Revoke the URL after use
                } else {
                    this.$root.notify('Your resume could not be retrieved at this time. Please try again later..', 'error');
                }
            })
            .catch((error) => {
              console.log(error)
              this.$root.notify('Something went wrong while fetching the resume for the selected candidate, please try again', 'error');
            })
            .finally(() => {
              this.toggleLoading(false);
            })
      },
      switchDecriptionEditing() {
          this.isEditingDescription = !this.isEditingDescription
      },
      async copyJobIdToClipboard() {
        try {
          await navigator.clipboard.writeText('#' + this.jobData.slug);

          // Reset copied status after 2 seconds
          this.$root.notify('Job ID #' + this.jobData.slug + ' has been copied to your clipboard.', 'success');
        } catch (err) {
           // Don't do anything really, if it's not copied it's not copied
        }
      },
      formatDatabaseString(str) {
          if (str) {
            return str.replace(/_/g, ' ').replace(/^./, (char) => char.toUpperCase());
          }
      }
    },
    mounted() {
      this.fetchJob();
      this.fetchApplicants();
    },
    computed: {
      formattedPostedDate() {
        const creationDate = new Date(this.jobData.creation_date);
        const now = new Date();
        const diffInMs = now - creationDate;
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert ms to hours

        if (diffInHours < 24) {
          return `Posted ${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
        }

        const diffInDays = Math.floor(diffInHours / 24);
        if (diffInDays === 1) {
          return "Posted 1 day ago";
        }

        return `Posted ${diffInDays} days ago`;
      },
    }
  };
  </script>
  
  <style scoped>
  /* Scoped styles */
  </style>
  