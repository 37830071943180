<template>
    <div
      class="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8"
    >
      <div class="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
        <div class="flex w-full flex-wrap gap-3 sm:gap-5">
            <div class="flex min-w-47.5">
            <span
              class="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-[#6b8c2d]"
            >
              <span class="block h-2.5 w-full max-w-2.5 rounded-full bg-[#6b8c2d]"></span>
            </span>
            <div class="w-full">
              <p class="font-semibold text-primary">Companies</p>
              <p class="text-sm font-medium">{{chartPastDate}} - {{ chartTodayDate }}</p>
            </div>
          </div>
          <div class="flex min-w-47.5">
            <span
              class="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-[#8bc34a]"
            >
              <span class="block h-2.5 w-full max-w-2.5 rounded-full bg-[#8bc34a]"></span>
            </span>
            <div class="w-full">
              <p class="font-semibold text-primary">Contractors</p>
              <p class="text-sm font-medium">{{chartPastDate}} - {{ chartTodayDate }}</p>
            </div>
          </div>
          <div class="flex min-w-47.5">
            <span
              class="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-[#80caee]"
            >
              <span class="block h-2.5 w-full max-w-2.5 rounded-full bg-[#80caee]"></span>
            </span>
            <div class="w-full">
              <p class="font-semibold text-primary">Jobs</p>
              <p class="text-sm font-medium">{{chartPastDate}} - {{ chartTodayDate }}</p>
            </div>
          </div>
          <div class="flex min-w-47.5">
            <span
              class="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-[#4a90e2]"
            >
              <span class="block h-2.5 w-full max-w-2.5 rounded-full bg-[#4a90e2]"></span>
            </span>
            <div class="w-full">
              <p class="font-semibold text-secondary">Applications</p>
              <p class="text-sm font-medium">{{chartPastDate}} - {{ chartTodayDate }}</p>
            </div>
          </div>
        </div>
        <div class="flex w-full max-w-45 justify-end">
          <div class="inline-flex items-center rounded-md bg-[#F5F7FD] p-1.5 dark:bg-meta-4">
            <button
              class="rounded py-1 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-card dark:text-white dark:hover:bg-boxdark"
              :class="{'bg-white shadow-card': chartSize == 7}"
              @click="switchChart(7)"
            >
              Week
            </button>
            <button
              class="rounded py-1 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-card dark:text-white dark:hover:bg-boxdark"
              :class="{'bg-white shadow-card': chartSize == 30}"
              @click="switchChart(30)"
            >
              Month
            </button>
          </div>
        </div>
      </div>
      <div>
        <div id="chartOne" class="-ml-5">
          <VueApexCharts
            type="area"
            height="350"
            :options="apexOptions"
            :series="chartData.series"
            ref="chart"
          />
        </div>
      </div>
    </div>

  </template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import adminService from '@/services/marketplace/admin.service'

export default {
    name: 'AdminChartMain',
    inject: ['toggleLoading'],
    components: {
        VueApexCharts,
    },
    data() {
        return {
            chartSize: 7,
            chartTodayDate: '',
            chartPastDate: '',
            chartData: {
                series: [
                    {
                        name: 'Companies registered',
                        data: []
                    },
                    {
                        name: 'Contractors registered',
                        data: []
                    },
                    {
                        name: 'Job posts',
                        data: []
                    },
                    {
                        name: 'Applications',
                        data: []
                    }
                ],
            },
            apexOptions: {
                legend: {
                    show: false,
                    position: 'top',
                    horizontalAlign: 'left'
                },
                colors: ['#6b8c2d', '#8bc34a', '#80caee', '#4a90e2'],
                chart: {
                    fontFamily: 'Satoshi, sans-serif',
                    height: 335,
                    type: 'area',
                    dropShadow: {
                    enabled: true,
                    color: '#623CEA14',
                    top: 10,
                    blur: 4,
                    left: 0,
                    opacity: 0.1
                    },

                    toolbar: {
                    show: false
                    }
                },
                responsive: [
                    {
                    breakpoint: 1024,
                    options: {
                        chart: {
                        height: 300
                        }
                    }
                    },
                    {
                    breakpoint: 1366,
                    options: {
                        chart: {
                        height: 350
                        }
                    }
                    }
                ],
                stroke: {
                    width: [2, 2],
                    curve: 'straight'
                },

                labels: {
                    show: false,
                    position: 'top'
                },
                grid: {
                    xaxis: {
                    lines: {
                        show: true
                    }
                    },
                    yaxis: {
                    lines: {
                        show: true
                    }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 4,
                    colors: '#fff',
                    strokeColors: ['#6b8c2d', '#8bc34a', '#80caee', '#4a90e2'],
                    strokeWidth: 3,
                    strokeOpacity: 0.9,
                    strokeDashArray: 0,
                    fillOpacity: 1,
                    discrete: [],
                    hover: {
                    size: undefined,
                    sizeOffset: 5
                    }
                },
                xaxis: {
                    type: 'category',
                    categories: [],
                    axisBorder: {
                    show: false
                    },
                    axisTicks: {
                    show: false
                    }
                },
                yaxis: {
                    title: {
                    style: {
                        fontSize: '0px'
                    }
                    },
                    min: 0,
                    max: 5
                }
            },
        }
    },
    methods: {
        getDateRange() {
            const today = new Date();

            let pastDate;
            if (this.chartSize === 7) {
                pastDate = new Date(today);
                pastDate.setDate(today.getDate() - 6); // Subtract 6 days as today is included
            } else if (this.chartSize === 30) {
                pastDate = new Date(today);
                pastDate.setDate(today.getDate() - 29); // Subtract 29 days
            }

            this.chartTodayDate = today.toISOString().split('T')[0]; // 'YYYY-MM-DD'
            this.chartPastDate = pastDate.toISOString().split('T')[0]; // 'YYYY-MM-DD'
        },
        switchChart(x) {
            if (this.chartSize !== x) {
                this.chartSize = x
                this.getChart()
                this.getDateRange()
            }
        },
        getChart() {
            adminService.getChart(this.chartSize)
            .then(response => {
                this.apexOptions.xaxis.categories = response.data.dates
                this.apexOptions.yaxis.max = response.data.max
                this.chartData.series[0].data = response.data.companies
                this.chartData.series[1].data = response.data.contractors
                this.chartData.series[2].data = response.data.jobs
                this.chartData.series[3].data = response.data.applications

            
                this.$refs.chart.updateOptions({
                    xaxis: {
                        categories: response.data.dates
                    },
                    yaxis: {
                        max: response.data.max * 2
                    }
                });
            })
            .catch((error) => {
            //   this.$root.notify('Something went wrong while fetching available jobs, please try again', 'error');
            })
            .finally(() => {
                this.$refs.chart.updateSeries(this.chartData.series);
            //   this.getApplications()
            //   this.loading = false
            //   this.toggleLoading(false);
            })
        }
    },
    created() {
        this.getChart()
        this.getDateRange()
    }
}
</script>