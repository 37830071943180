<template>
    <div class="flex mt-5 mx-auto max-w-7xl px-5">
        <div class="flex flex-col space-y-5 w-full">
            <!-- Settings Header -->
            <div class="p-6 bg-sky-500 text-white rounded-lg">
                <h2 class="text-2xl leading-none tracking-tight lg:text-3xl text-white">Settings</h2>
            </div>

            <div class="flex flex-col md:flex-row w-full">
                <div class="flex flex-col w-full md:w-1/2 bg-white shadow-md rounded-lg p-6">
                    <div class="mb-4">
                        <h3 class="font-bold">Account settings</h3>
                    </div>

                    <div class="flex flex-wrap -mx-3 mb-2">
                        <div class="w-full px-3 mb-6 relative">
                            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-email">
                                Email
                            </label>
                            <div class="relative">
                                <!-- Input field -->
                                <input disabled class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 pr-12 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50" id="grid-email" type="text" v-model="emailAddress"/>

                                <!-- Inner Rounded Icon Container with Hover Effect -->
                                <div class="absolute inset-y-0 right-3 flex items-center" @click="toggleEmailEdit">
                                    <!-- Nested div with rounded background and hover effect -->
                                    <div class="w-8 h-8 flex items-center justify-center bg-transparent rounded-full hover:bg-gray-300 hover:text-sky-800 cursor-pointer p-1 text-sky-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                        </svg>
                                    </div>
                                </div>
                                
                            </div>

                            <div v-show="isEditingEmail" class="shadow-lg rounded-lg p-6 space-y-4 mt-5">
                                <p class="font-semibold">Update email address</p>
                                <p class="text-sm text-gray-500">Please note that changing your email address will update the way you log in to your account and will also change the email visible to employers on any job applications you have submitted.</p>
                                <div class="w-full-mb-4 relative">
                                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-newEmailAddress">
                                        New email address
                                    </label>
                                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-newEmailAddress" type="text" v-model="newEmailAddress">
                                </div>
                                <div class="w-full-mb-4 relative">
                                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-newEmailAddressConfirm">
                                        Confirm email address
                                    </label>
                                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-newEmailAddressConfirm" type="text" v-model="newEmailAddressConfirm">
                                </div>
                                <div class="w-full-mb-4 relative">
                                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-newEmailAddressPassword">
                                        Account password
                                    </label>
                                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-newEmailAddressPassword" type="password" v-model="newEmailPassword">
                                </div>
                                <!-- Save Button -->
                                <div class="mt-4">
                                <button @click="submitEmailChange()" class="w-full py-3 px-4 bg-blue-500 text-white rounded-full hover:bg-blue-700 transition">
                                    Save Changes
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="flex flex-wrap -mx-3 mb-2">
                        <div class="w-full px-3 mb-6 relative">
                            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-email">
                                Password
                            </label>
                            <div class="relative">
                                <!-- Input field -->
                                <input disabled class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 pr-12 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50" id="grid-email" type="text" value="• • • • • • • • • •">

                                <!-- Inner Rounded Icon Container with Hover Effect -->
                                <div class="absolute inset-y-0 right-3 flex items-center" @click="togglePasswordEdit()">
                                    <!-- Nested div with rounded background and hover effect -->
                                    <div class="w-8 h-8 flex items-center justify-center bg-transparent rounded-full hover:bg-gray-300 hover:text-sky-800 cursor-pointer p-1 text-sky-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div v-show="isEditingPassword" class="shadow-lg rounded-lg p-6 space-y-4 mt-5">
                                <p class="font-semibold">Update account password</p>
                                <div class="w-full-mb-4 relative">
                                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-currentPassword">
                                        Current password
                                    </label>
                                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-currentPassword" type="password" v-model="currentPassword">
                                </div>
                                <div class="w-full-mb-4 relative">
                                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-newPassword">
                                        New password
                                    </label>
                                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-newPassword" type="password" v-model="newPassword">
                                </div>
                                <div class="w-full-mb-4 relative">
                                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-newPasswordConfirm">
                                        Confirm password
                                    </label>
                                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-newPasswordConfirm" type="password" v-model="newPasswordConfirm">
                                </div>
                                <!-- Save Button -->
                                <div class="mt-4">
                                <button @click="submitPasswordChange()" class="w-full py-3 px-4 bg-blue-500 text-white rounded-full hover:bg-blue-700 transition">
                                    Save Changes
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex w-full md:w-1/2 items-center justify-center mt-5 md:mt-0">
                    <div class="flex flex-col items-center justify-center">
                        <div v-if="!hasPhotoLoaded" class="w-24 h-24 bg-gray-300 rounded-full flex justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-16 w-16 text-sky-500">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                            </svg>
                        </div>

                        <div v-else class="w-44 h-44 bg-olive rounded-full flex justify-center items-center bg-sky-500">
                            <div class="w-40 h-40 rounded-full overflow-hidden">
                                <img :src="profilePicture" class="w-full h-full object-cover">
                            </div>
                        </div>

                        <p class="py-5 font-semibold">A photo increases your chances of getting hired.</p>

                        <input type="file" ref="fileInput" class="hidden" @change="handleProfilePictureUpload" />

                        <div class="my-5">
                            <button @click="loadNewPhoto()" class="bg-sky-500 text-sm text-weight text-white font-bold py-2 px-6 rounded-full font-thin">
                                UPLOAD PHOTO
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <ImageCropperModal
        v-if="showModal"
        :image="profilePicturePreview"
        :show="showModal"
        @close="closeModal"
        @save="saveImage"
    />
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL
import contractorService from '@/services/marketplace/contractor.service';
import genericService from '@/services/marketplace/generic.service'
import ImageCropperModal from '@/components/modals/ImageCropperModal.vue';  // Import the standalone modal component

export default {
    inject: ['toggleLoading'],
    components: {
        ImageCropperModal
    },
    data() {
        return {
            profile: [],
            emailAddress: 'Loading...',
            newEmailAddress: '',
            newEmailAddressConfirm: '',
            newEmailPassword: '',
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
            isEditingEmail: false,
            isEditingPassword: false,
            isEditing: true,
            hasPhotoLoaded: false,
            profilePicture: '',
            profilePicturePreview: '',
            showModal: false,
            profilePictureForm: {
                profilePicture: '',
                croppedImage: ''
            }
        }
    },
    methods: {
        toggleEmailEdit() {
            this.isEditingEmail = !this.isEditingEmail
        },
        togglePasswordEdit() {
            this.isEditingPassword = !this.isEditingPassword
        },
        async getContractorProfile() {
            this.toggleLoading(true);
            try {
                let response = await fetch(apiEndpoint + "/marketplace.php?getContractorAccount", {
                    headers: {
                        'Authorization': 'Bearer ' +  localStorage.getItem('contractorToken'),
                    },
                })
                
                this.profile = await response.json();

                this.emailAddress = this.profile.email

                if (this.profile.photo_hash) {
                    this.getImage(this.profile.photo_hash)
                }

                this.toggleLoading(false);
            } catch (error) {
                console.log(error)
                this.toggleLoading(false);
            }
        },
        async validateEmailChangeForm() {
            // Reset error state
            this.error = false;
            this.errorMessage = '';

            let isValid = true;

            if (!this.newEmailAddress || !this.newEmailAddressConfirm || !this.newEmailPassword) {
                this.errorMessage = 'Please fill all the fields.'
                isValid = false;
            }

            if (this.newEmailAddress === this.emailAddress) {
                this.errorMessage = 'The new email address cannot be the same as your current email address. Please enter a different email.';
                isValid = false;
            }

            if (this.newEmailAddress !== this.newEmailAddressConfirm) {
                this.errorMessage = 'The new email address and the confirmation email do not match. Please ensure both email addresses are identical.';
                isValid = false;
            }

            // If valid, emit the formData
            if (!isValid) {
                this.$root.notify(this.errorMessage, 'error');
            }

            return isValid;
            
        },
        async submitEmailChange() {
            this.toggleLoading(true);
            let isValid = await this.validateEmailChangeForm()

            if (isValid) {
                let postObject = {
                    setting: 'email',
                    newEmailAddress: this.newEmailAddress,
                    newEmailAddressConfirm: this.newEmailAddressConfirm,
                    accountPassword: this.newEmailPassword
                }

                contractorService.updateContractorSettings(postObject)
                .then(response => {
                    if (response.data.error) {
                        this.$root.notify(response.data.errorMessage, 'error');
                    } else {
                        this.$root.notify('Your email address has been successfully updated!', 'success');
                        this.newEmailAddress = ''
                        this.newEmailAddressConfirm = ''
                        this.newEmailPassword = ''
                        this.isEditingEmail = false;
                        this.getContractorProfile()
                    }
                })
                .catch((error) => {
                    this.$root.notify('Something went wrong while updating your account settings, please try again', 'error');
                })
                .finally(() => {
                    this.toggleLoading(false);
                })
            } else {
                this.toggleLoading(false);
            }
        },
        async validatePasswordChangeForm() {
            // Reset error state
            this.error = false;
            this.errorMessage = '';

            let isValid = true;

            if (!this.currentPassword || !this.newPassword || !this.newPasswordConfirm) {
                this.errorMessage = 'Please fill all the fields.'
                isValid = false;
            }

            if (this.newPassword === this.currentPassword) {
                this.errorMessage = 'The new password cannot be the same as your current password.';
                isValid = false;
            }

            // Validate password length (e.g., minimum 8 characters)
            if (!this.newPassword || this.newPassword.length < 8) {
                this.errorMessage = 'Password must be at least 8 characters long.';
                isValid = false;
            }

            if (this.newPassword !== this.newPasswordConfirm) {
                this.errorMessage = 'The new password and the confirmation password do not match. Please ensure both passwords are identical.';
                isValid = false;
            }

            // If valid, emit the formData
            if (!isValid) {
                this.$root.notify(this.errorMessage, 'error');
            }

            return isValid;
            
        },
        async submitPasswordChange() {
            this.toggleLoading(true);
            let isValid = await this.validatePasswordChangeForm()

            if (isValid) {
                let postObject = {
                    setting: 'password',
                    currentPassword: this.currentPassword,
                    newPassword: this.newPassword,
                    newPasswordConfirm: this.newPasswordConfirm
                }

                contractorService.updateContractorSettings(postObject)
                .then(response => {
                    if (response.data.error) {
                        this.$root.notify(response.data.errorMessage, 'error');
                    } else {
                        this.$root.notify('Your password has been successfully updated!', 'success');
                        this.currentPassword = ''
                        this.newPassword = ''
                        this.newPasswordConfirm = ''
                        this.isEditingPassword = false;
                    }
                })
                .catch((error) => {
                    this.$root.notify('Something went wrong while updating your account settings, please try again', 'error');
                })
                .finally(() => {
                    this.toggleLoading(false);
                })
            } else {
                this.toggleLoading(false);
            }
        },
        getImage(hash) {
            this.profilePicture = genericService.getProfilePictureLink(hash)

            if (this.profilePicture) {
                this.hasPhotoLoaded = true
            }
        },
        loadNewPhoto() {
            this.$refs.fileInput.click();
        },
        handleProfilePictureUpload(event) {
            const file = event.target.files[0];

            if (!file) {
                // Handle cancel event (when no file is selected)
                return; // Simply return if no file was selected
            }

            if (file && file.type.startsWith('image/')) {
                this.profilePictureForm.profilePicture = file;  // Store the uploaded file in formData
                this.profilePicturePreview = URL.createObjectURL(file);  // Generate preview URL
                this.showModal = true;
            } else {
                this.showError('Please upload a valid image file.');
            }
        },
        saveImage(imageData) {
            this.toggleLoading(true);

            this.profilePictureForm.croppedImage = imageData
            this.showModal = false;

            const formData = new FormData();
            formData.append('setting', 'profilePicture');
            formData.append('croppedImage', this.profilePictureForm.croppedImage);
            formData.append('profilePicture', this.profilePictureForm.profilePicture);

            contractorService.updateContractorProfilePicture(formData)
            .then(response => {
                    if (response.data.error) {
                        this.$root.notify(response.data.errorMessage, 'error');
                    } else {
                        this.$root.notify('Your profile picture has been successfully updated!', 'success');
                        this.profilePictureForm.profilePicture = ''
                        this.profilePictureForm.croppedImage = ''

                        this.getContractorProfile()
                    }
                })
                .catch((error) => {
                    this.$root.notify('Something went wrong while updating your account settings, please try again', 'error');
                })
                .finally(() => {
                    this.toggleLoading(false);
                })
        },
        closeModal() {
            this.showModal = false;
        },
    },
    created() {
        this.getContractorProfile()
    }
}
</script>