<template>
    <div class="flex mt-5 mx-auto max-w-7xl px-5">
      <div class="flex flex-col space-y-5 w-full">
            <!-- Manage Jobs Header -->
            <div class="p-6 bg-sky-500 text-white rounded-lg">
                <h2 class="text-2xl leading-none tracking-tight lg:text-3xl text-white">Edit Profile</h2>
            </div>

 
            <div class="bg-white shadow-md rounded-lg p-6">
                <div class="mb-4">
                <h3 class="font-bold">Personal Details</h3>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-firstName">
                        First name
                    </label>
                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-firstName" type="text" v-model="profile.first_name">
                </div>
                <div class="w-full md:w-1/2 px-3">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-lastName">
                        Last name
                    </label>
                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-lastName" type="text" v-model="profile.last_name">
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-full px-3 mb-6">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-phone">
                        Phone
                    </label>
                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-Phone" type="text" v-model="profile.phone">
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
                <!-- Street Address -->
                <div class="w-full px-3 mb-6">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-address">
                      Street Address
                    </label>
                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-address" type="text" v-model="profile.address">
                </div>

                <!-- Suburb -->
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-suburb">
                        Suburb
                    </label>
                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-suburb" type="text" v-model="profile.suburb">
                </div>

                <!-- State -->
                <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-state">
                        State
                    </label>
                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-state" type="text" v-model="profile.state">
                </div>

                <!-- Post Code -->
                <div class="w-full md:w-1/4 px-3">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-postCode">
                        Post Code
                    </label>
                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-postCode" type="text" v-model="profile.post_code">
                </div>
            </div>
            
            <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-visaStatus">
                        Visa status
                    </label>

                    <select class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-visaStatus" v-model="profile.visa_status">
                        <option value="Citizen">Citizen</option>
                        <option value="Permanent Resident">Permanent Resident</option>
                        <option value="Visa with full working rights">Visa with full working rights</option>
                        <option value="Visa that requires sponsorship">Visa that requires sponsorship</option>
                        <option value="Working Holiday Visa">Working Holiday Visa</option>
                        <option value="I’m not a citizen and don’t hold a visa">I’m not a citizen and don’t hold a visa</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </div>

            <div class="mb-4">
                <h3 class="font-bold">Career Info</h3>
            </div>

            <!-- Industry Dropdown -->
            <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                    <label for="industry" class="block tracking-wide text-gray-800 text-sm mb-2">Select Industry</label>
                    <select id="industry" v-model="this.selectedIndustry" @change="changeIndustry" class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight">
                        <option disabled selected value="">-- Select Industry --</option>
                        <option v-for="industry in industries" :key="industry.id" :value="industry.id">{{ industry.name }}</option>
                    </select>
                </div>
            </div>

            <!-- Roles Dropdown (shown when an industry is selected) -->
            <div v-if="roles.length > 0" class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                    <label for="role" class="block tracking-wide text-gray-800 text-sm mb-2">Select Role</label>
                        <select id="role" v-model="this.selectedRole" @change="fetchQuestions" class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight">
                              <option disabled value="">-- Select Role --</option>
                              <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                        </select>
                  </div>
            </div>

            <!-- Questions and Text Areas (shown when questions are fetched for the selected role) -->
            <div v-if="this.questions && this.questions.length > 0">
              <div v-for="(question, index) in this.questions" :key="question.id" class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                  <!-- Display the question -->
                  <label :for="'question-' + question.id" class="block tracking-wide text-gray-800 text-sm mb-2">
                    {{ question.question }}
                  </label>

                  <!-- Textarea for the user's answer to the question -->
                  <textarea
                      :id="'question-' + question.id"
                      v-model="this.answers[index]"
                      rows="4"
                      class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight"
                      placeholder="Write your answer here..."
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
                <!-- Availability -->
                <div class="w-full md:w-5/12 px-3 mb-6 md:mb-0">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-availability">
                        Availability
                    </label>
                        
                    <div class="relative">
                        <select @change="availabilityChange($event)" class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight" id="grid-availability" v-model="this.selectedAvailability">
                            <option disabled value="">Select an option</option>
                            <option v-for="availability in availabilityOptions" :value="availability.value">{{ availability.label }}</option>
                        </select>
                    </div>

                    <Popover v-if="calendarOpen" class="relative">
                        <PopoverPanel class="absolute z-10">
                            <Datepicker class="my-3" v-model="date" :min-date="today" inline autoApply @update:modelValue="updateCalendarDate" hideOffsetDates :enableTimePicker="false" ref="datepicker"></Datepicker>
                        </PopoverPanel>
                    </Popover>
                </div>

                <!-- Rate -->
                <div class="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-rate">
                    Rate
                    </label>
                    <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-rate" type="number" v-model="profile.rate">
                </div>

                <!-- Rate type -->
                <div class="w-full md:w-4/12 px-3">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-rateType">
                    Rate type
                    </label>
                    <select class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-rateType" v-model="profile.rate_type">
                        <option disabled value="">Select an option</option>
                        <option value="hour">Hourly</option>
                        <option value="day">Daily</option>
                    </select>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3 mb-6 md:mb-0">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-contractStatus">
                        Contract Status
                    </label>
                    <select class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-contractStatus" v-model="profile.contract_type">
                        <option disabled value="">Select an option</option>
                        <option value="PTY">PTY</option>
                        <option value="PAYG">PAYG</option>
                    </select>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                    <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-roleDescription">
                        Short description of preferred role/project
                    </label>
                    <textarea class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight" name="" id="grid-roleDescription" cols="30" rows="3" v-model="profile.ideal_role"></textarea>
                </div>
            </div>

            <div class="flex items-end justify-between">
                <router-link to="/contractor/profile">
                  <button class="bg-sky-500 hover:bg-sky-400 text-white py-2 px-4 rounded" type="button">
                      Back
                  </button>
                </router-link>

                <button class="bg-sky-500 hover:bg-sky-400 text-white py-2 px-4 rounded" type="button" @click="submitUpdate">
                    Save changes
                </button>
            </div>
        </div>
    </div>
    </div>
</template>

<script>

let apiEndpoint = process.env.VUE_APP_API_BASEURL
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import ImageCropperModal from '@/components/modals/ImageCropperModal.vue';  // Import the standalone modal component
import contractorService from '@/services/marketplace/contractor.service';
import genericService from '../../../services/marketplace/generic.service';
  
export default {
  name: 'MarketplaceContractorProfileEdit',
  inject: ['toggleLoading'],
  components: {
    Datepicker,
    ImageCropperModal
  },
  data() {
    return {
      date: new Date(),
      today: new Date(),
      profile: [],
      availability: 0,
      calendarOpen: false,
      availabilityOptions: [
        { "value": 0.1, "label": "Now"},
        { "value": 0.2, "label": "1 week" },
        { "value": 0.5, "label": "2 weeks" },
        { "value": 1, "label": "1 month" },
        { "value": 999, "label": "Custom date" }
      ],
      profilePicturePreview: null,  // Holds the image URL
      showModal: false,    // Controls whether the modal is shown
      selectedAvailability: 0,
      resumeData: false,
      editResumeData: false,
      isFileUploaded: false,
      uploadedFile: null,
      fileSuccess: false,
      fileError: false,
      fileErrorMessage: '',
      formData: {
        profilePicture: null
      },
      industries: [],         // List of industries fetched from API
      selectedIndustry: '',   // Selected industry ID
      roles: [],
      selectedRole: '',
      questions: [],
      answers: [],
    };
  },
  methods: {
    async getResume() {
      let response = await fetch(apiEndpoint + "/marketplace.php?getResume", {
        headers: {
          'Authorization': 'Bearer ' +  localStorage.getItem('contractorToken'),
        },
      })

      let resp = await response.json();

      if (resp.success !== false) {
        this.resumeData = resp.data
      }
    },
    fileUpload(event) {
      const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      this.fileSuccess = false
      this.fileError = false
      this.fileErrorMessage = ''
      
      
      let currentFile = event.target.files[0]
      if (allowedTypes.includes(currentFile.type)) {
        this.uploadedFile = event.target.files[0]
        this.isFileUploaded = true
      } else {
        this.fileError = true
        this.fileErrorMessage = 'Invalid file selected. Only PDF, DOC and DOCX files are allowed.'
      }
    },
    cancelUpload() {
      this.uploadedFile = null
      this.isFileUploaded = false
      this.fileError = false
      this.fileErrorMessage = ''
    },
    async processResumeUpload() {
      if (this.isFileUploaded) {
        var data = new FormData()
        data.append('file', this.uploadedFile)

        fetch(apiEndpoint + "/marketplace.php?uploadResume", {
          method: 'POST',
          body: data,
          headers: {
            'Authorization': 'Bearer ' +  localStorage.getItem('contractorToken'),
          },
        })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            this.uploadedFile = null
            this.isFileUploaded = false
            this.fileError = true
            this.fileErrorMessage = data.errorMessage
          } else if (data.success) {
            this.uploadedFile = null
            this.isFileUploaded = false
            this.fileError = false
            this.fileErrorMessage = ''
            this.fileSuccess = true

            this.getResume()
          }
        })
      }
    },
    async getContractorProfile() {
        this.toggleLoading(true);

        try {
            let response = await fetch(apiEndpoint + "/marketplace.php?getContractorAccount", {
                headers: {
                'Authorization': 'Bearer ' +  localStorage.getItem('contractorToken'),
            },
        })
        this.profile = await response.json();
        this.setAvailability(this.profile.availability)
        // If the availability is higher than 1 we need to add the custom date to the array
        if (this.availabilityOptions.length >= 6) {
          this.availabilityOptions.pop();
        }

        if (this.selectedAvailability > 1) {
          let customDate = new Date(this.profile.availability * 1000)
          this.availabilityOptions.push({
            "value": this.selectedAvailability,
            "label": 'From: ' + customDate.toLocaleDateString()
          })
        }

        // Preset dropdown values
        this.selectedIndustry = this.profile.industryId
        this.selectedRole = this.profile.roleId

        await this.fetchRoles()
        await this.fetchQuestions()
        await this.getContractorResponses()
        this.toggleLoading(false);
      } catch (error) {
        this.toggleLoading(false);
      }
    },
    setAvailability(v) {
      if (v == null) {
        this.selectedAvailability = 0
      } else {
        this.selectedAvailability = v
      }
    },
    async changeIndustry() {
        this.selectedRole = ''
        await this.fetchRoles()
    },
    async fetchRoles() {
        this.roles = [];

        this.questions = []
        this.answers = []

        // Populate roles
        let roleData = await genericService.getRoles(this.selectedIndustry)
        this.roles = roleData.data

        // Populate quetions
        if (this.selectedRole) {
          let questions = await genericService.getQuestions(this.selectedRole)
        }
    },
    async validateForm() {
        // Reset error state
        this.error = false;
        this.errorMessage = '';

        let isValid = true;

        if (!this.profile.ideal_role) {
            this.errorMessage = 'Please provide a short description of your preferred role.'
            isValid = false;
        }

        if (!this.profile.contract_type) {
            this.errorMessage = 'The Contract Status field is mandatory.';
            isValid = false;
        }

        // Validate rate type
        if (!this.profile.rate_type) {
            this.errorMessage = 'Please select a rate type.'
            isValid = false;
        }

        // Validate rate positive number
        if (!/^\d+(\.\d+)?$/.test(this.profile.rate) || parseFloat(this.profile.rate) <= 0) {
            this.errorMessage = 'The rate value has to be a positive number';
            isValid = false;
        }

        // Validate rate
        if (!this.profile.rate) {
            this.errorMessage = 'Select a valid rate (excl. super).'
            isValid = false;
        }

        // Validate availability
        if (!this.selectedAvailability) {
            this.errorMessage = 'Please select your availability.';
            isValid = false;
        }

        // Validation for dynamic answers array
        this.answers.forEach((answer, index) => {
            if (!answer || answer.trim() === '') {
              this.errorMessage = 'All questions are mandatory.';
              isValid = false;
            }
        });

        if (!this.selectedRole) {
            this.errorMessage = 'Please select a role from the list.';
            isValid = false;
        }

        if (!this.selectedIndustry) {
            this.errorMessage = 'Please select an industry from the list.'
            isValid = false;
        }

        // Validate visa status
        if (!this.profile.visa_status) {
            this.errorMessage = 'A valid visa status is required.';
            isValid = false;
        }

        if (!this.profile.address || !this.profile.suburb || !this.profile.state || !this.profile.post_code ||  this.profile.address.length < 5) {
            this.errorMessage = 'Please enter a valid address.';
            isValid = false;
        }

        // Validate phone number
        if (!this.profile.phone) {
            this.errorMessage = 'A valid phone number is required.';
            isValid = false;
        }

        // Validate first and last name
        if (!this.profile.first_name || !this.profile.last_name) {
            this.errorMessage = 'Please provide both your first and last name.';
            isValid = false;
        }

        // If valid, emit the formData
        if (!isValid) {
          this.$root.notify(this.errorMessage, 'error');
        }

        return isValid;
        
    },
    async submitUpdate() {
      this.toggleLoading(true);
        let isValid = await this.validateForm()

        if (isValid) {
            let postObject = {
                first_name: this.profile.first_name,
                last_name: this.profile.last_name,
                phone: this.profile.phone,
                address: this.profile.address,
                suburb: this.profile.suburb,
                state: this.profile.state,
                post_code: this.profile.post_code,
                visa_status: this.profile.visa_status,
                industry: this.selectedIndustry,
                role: this.selectedRole,
                availability: this.selectedAvailability,
                rate: this.profile.rate,
                rate_type: this.profile.rate_type,
                contract_type: this.profile.contract_type,
                ideal_role: this.profile.ideal_role,
                questions: JSON.stringify(this.questions),
                answers: JSON.stringify(this.answers)
            }

            contractorService.updateContractorProfile(postObject)
            .then(response => {
                this.jobs = response.data;
                this.$root.notify('Your profile has been updated.', 'success');
            })
            .catch((error) => {
              this.$root.notify('Something went wrong while updating your profile information, please try again', 'error');
            })
            .finally(() => {
              this.toggleLoading(false);
            })
        } else {
            this.toggleLoading(false);
        }
    },
    availabilityChange(event) {
      if (event.target.value == 999) {
        this.calendarOpen = true
      } else {
        this.calendarOpen = false
      }
    },
    updateCalendarDate(newDate) {
      let newSelectedDate = (newDate.getTime() / 1000)
      this.calendarOpen = false

      // If we already have 5 dates, remove the latest one
      if (this.availabilityOptions.length >= 6) {
        this.availabilityOptions.pop();
      }

      // Add the new date to the dropdown
      this.availabilityOptions.push({
        "value": newSelectedDate,
        "label": "From " + newDate.toLocaleDateString()
      })

      this.selectedAvailability = newSelectedDate
    },
    closeModal() {
      this.showModal = false;
    },
    // Save the cropped image
    saveImage(imageData) {
      this.formData.croppedImage = imageData
      this.showModal = false;
    },
    handleProfilePictureUpload(event) {
      const file = event.target.files[0];

      if (!file) {
        // Handle cancel event (when no file is selected)
        return; // Simply return if no file was selected
      }

      if (file && file.type.startsWith('image/')) {
        this.formData.profilePicture = file;  // Store the uploaded file in formData
        this.profilePicturePreview = URL.createObjectURL(file);  // Generate preview URL
        this.showModal = true;
      } else {
        this.showError('Please upload a valid image file.');
      }
    },
    async fetchQuestions() {
      this.questions = [];
      this.answers = [];

      if (this.selectedRole) {
        try {
          const response = await genericService.getQuestions(this.selectedRole);
          this.questions = response.data;

          // Initialize answers array with empty strings, one for each question
          this.answers = this.questions.map(() => '');
          if (!Array.isArray(this.answers)) {
            this.answers = [this.answers];
          }
        } catch (error) {
          console.error('Error fetching questions:', error);
        }
      }
    },
    async getContractorResponses() {
      try {
        let response = await fetch(apiEndpoint + "/marketplace.php?getContractorResponses", {
          headers: {
            'Authorization': 'Bearer ' +  localStorage.getItem('contractorToken'),
          },
        })
        let answersData = await response.json();
        this.answers = answersData.map(answer => answer.response);
      } catch (error) {
        // console.log(error);
        // console.log(error)
        }
      },
  },
  created() {
    this.getContractorProfile();
    (async () => this.industries = (await genericService.getIndustries()).data)()
  }
}
</script>