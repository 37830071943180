<template>
    <div class="flex mt-5 mx-auto max-w-7xl px-5">
        <template v-if="loading">
            <div class="flex flex-col space-y-5 w-full">
                <div class="p-6 bg-sky-500 text-white rounded-lg">
                    <h2 class="text-2xl leading-none tracking-tight lg:text-3xl text-white">Job search</h2>
                </div>
                <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <JobCardLoading></JobCardLoading>
                    <JobCardLoading></JobCardLoading>
                    <JobCardLoading></JobCardLoading>
                    <JobCardLoading></JobCardLoading>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="jobs.length == 0" class="flex flex-col w-full items-center py-12 bg-white rounded-lg">
                <h3>No jobs are currently available</h3>
                <p>Check back later or explore other opportunities to stay ahead in your career journey</p>
            </div>

            <div v-else class="flex flex-col space-y-5 w-full">
                <div class="p-6 bg-sky-500 text-white rounded-lg">
                    <h2 class="text-2xl leading-none tracking-tight lg:text-3xl text-white">Job search</h2>
                </div>
                <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <JobCard
                        v-for="j in jobs"
                        :key="j.job_id"
                        :vJobId="j.job_id"
                        :vSlug="j.job_slug"
                        :vTitle="j.job_title"
                        :vCompany="j.company_name"
                        :vLocation="j.job_location"
                        :vIndustry="j.job_industry"
                        :vRole="j.job_role"
                        :vDate="j.job_date"
                        :vAppDate="j.application_date"
                        :vRateCategory="j.rate_category"
                        :vRate="j.job_rate"
                        :vRateType="j.job_rate_type"
                        :vStartDate="j.job_start">
                    </JobCard>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import contractorService from '@/services/marketplace/contractor.service';
import JobCard from '@/components/marketplace/generic/JobCard'
import JobCardLoading from '@/components/marketplace/generic/JobCardLoading'

export default {
    name: 'ContractorJobs',
    inject: ['toggleLoading'],
    components: {
        JobCard,
        JobCardLoading
    },
    data() {
        return {
            loading: true,
            jobs: [],
            applications: [],
            apps:[]
        };
    },
    methods: {
        async fetchJobs() {
            // this.toggleLoading(true)
            contractorService.getJobs()
            .then(response => {
                this.jobs = response.data;
            })
            .catch((error) => {
              this.$root.notify('Something went wrong while fetching available jobs, please try again', 'error');
            })
            .finally(() => {
            //   this.getApplications()
              this.loading = false
              this.toggleLoading(false);
            })
        },
        getApplications() {
            contractorService.getApplications()
            .then(response => {
                this.applications = response.data;
            })
        },
        formattedPostedDate(creationDateString) {
            const creationDate = new Date(creationDateString);
            const now = new Date();
            const diffInMs = now - creationDate;
            const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert ms to hours

            if (diffInHours < 24) {
                return `Posted ${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
            }

            const diffInDays = Math.floor(diffInHours / 24);
            if (diffInDays === 1) {
                return "Posted 1 day ago";
            }

            return `Posted ${diffInDays} days ago`;
        },
    },
    mounted() {
      this.fetchJobs();
    },
}
</script>