<template>
    <div class="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
        <div class="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
            <h2 class="text-title-md2 font-semibold text-black dark:text-white">Companies</h2>
            <nav>
                <ol class="hidden flex items-center gap-2">
                    <li><a href="/" class="font-medium"> Dashboard / </a></li>
                    <li class="font-medium text-primary">Pro Tables</li>
                </ol>
            </nav>
        </div>

        <div class="flex flex-col gap-10">
            <div class="overflow-hidden rounded-[10px]">
                <div class="max-w-full overflow-x-auto">
                    <div class="min-w-[1170px]">
                        <div class="grid grid-cols-12 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
                            <div class="col-span-3">
                                <h5 class="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
                            </div>

                            <div class="col-span-3">
                                <h5 class="font-medium text-[#637381] dark:text-bodydark">MANAGER (TITLE)</h5>
                            </div>

                            <div class="col-span-3">
                                <h5 class="font-medium text-[#637381] dark:text-bodydark">EMAIL</h5>
                            </div>

                            <div class="col-span-2">
                                <h5 class="font-medium text-[#637381] dark:text-bodydark">INDUSTRY</h5>
                            </div>
                        </div>

                        <div class="bg-white dark:bg-boxdark">
                            <div v-for="company in companies" :key="company.id" class="grid grid-cols-12 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11 hover:bg-sky-100">
                                <div class="col-span-3">
                                    <p class="text-[#637381] dark:text-bodydark">{{ company.name }}</p>
                                </div>

                                <div class="col-span-3">
                                    <p class="text-[#637381] dark:text-bodydark">{{ company.first_name }} {{ company.last_name }} ({{ company.title }})</p>
                                </div>

                                <div class="col-span-3">
                                    <p class="text-[#637381] dark:text-bodydark"><a class="text-blue-300" :href="`mailto:${company.email}`">{{ company.email }}</a></p>
                                </div>

                                <div class="col-span-2">
                                    <p class="text-[#637381] dark:text-bodydark">{{ company.industry_name }}</p>
                                </div>

                                <div class="col-span-1">
                                    <button class="float-right text-primary">View</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'AdminTableCompany',
    props: ['companies'],
    components: {},
    data() {
        return {
            columns: [
                { data: 'name', title: 'Name' },
                { data: 'first_name', title: 'Manager' },
                { data: 'title', title: 'Title' },
                { data: 'email', title: 'Email' },
                
            ]
        }
    },
    created() {
        console.log(this.companies)
    }
}
</script>