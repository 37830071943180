<template>
<div class="min-h-screen flex items-center justify-center mt-5">
  <div class="max-w-md w-full bg-white p-6 rounded-2xl shadow-md">
    <h2 class="text-2xl font-semibold text-gray-800 mb-4 text-center">Admin Login</h2>
    <div v-if="!submitted" class="space-y-4">
      <div>
        <label for="email" class="block text-sm font-medium text-gray-600 mb-1">Email Address</label>
        <input 
          v-model="emailAddress"
          type="email" 
          id="email" 
          name="email" 
          required 
          class="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none text-gray-800"
          placeholder="Enter your email"
        />
      </div>
      <button 
        type="submit"
        @click="generateLogin()"
        class="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-1">
        Send Login Link
      </button>
    </div>

    <div v-else>
        <div class="text-center">A login link has been sent to your email. Please check your inbox to proceed with signing in.</div>
        <button 
        type="submit"
        @click="backLogin()"
        class="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-1 mt-2">
        Back to login page
      </button>
    </div>
  </div>
</div>
</template>

<script>
import adminService from '@/services/marketplace/admin.service';

export default {
    name: 'AdminAuthentication',
    inject: ['toggleLoading'],
    data() {
        return {
            emailAddress: '',
            submitted: false
        }
    },
    methods: {
        async generateLogin() {
          this.toggleLoading(true)

            adminService.generateAuthenticationRequest(this.emailAddress)
            .then(response => {
                this.submitted = true
            })
            .catch((error) => {
                this.$root.notify('Something went wrong while sending your authentication email, please try again', 'error');
            })
            .finally(() => {
                this.toggleLoading(false);
            })
        },
        backLogin() {
          this.submitted = false
        }
    }
}
</script>