<template>
    <header class="bg-white drop-shadow-md">
      <nav class="flex justify-between items-center text-white p-6 xl:container xl:mx-auto">
        <div class="flex">
          <a href="" class="logo">
            <img class="w-1/2 ml-2" src="@/assets/logo.png" alt="Screened logo">
          </a>
        </div>
  
      </nav>
    </header>
  </template>
  
  <script>
  export default {
    name: 'BlankHeader',
  }
  </script>