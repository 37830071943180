import axios from 'axios';

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    async checkEmail(emailAddress) {
        return await axios.post(`${apiEndpoint}/company/checkAvailableEmail`, { email: emailAddress }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    register(formData) {
        // Sending formData using multipart/form-data
        return axios.post(`${apiEndpoint}/company/register`, formData, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    login(email, password) {
        return axios.post(`${apiEndpoint}/company/login`, { email: email, password: password }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    async getCompanyAccount() {
        return axios.get(`${apiEndpoint}/company/get-account`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    async completeRegistration(data) {
        return axios.post(`${apiEndpoint}/company/complete-registration`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    async getJobs() {
        return axios.get(`${apiEndpoint}/company/get-jobs`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    async getJob(jobId) {
        return axios.get(`${apiEndpoint}/company/get-job/` + jobId, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    async getDashboard() {
        return axios.get(`${apiEndpoint}/company/dashboard`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    async getApplications(jobId) {
        return axios.get(`${apiEndpoint}/company/get-applicants/` + jobId, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    async downloadApplicantResume(jobId, applicantId) {
        return axios.get(`${apiEndpoint}/company/get-resume/${jobId}/${applicantId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            },
            responseType: 'blob'  // Tell Axios to treat the response as a Blob
        });
    },
    async getApplicantProfile(jobId, applicantId) {
        return axios.get(`${apiEndpoint}/company/get-profile/${jobId}/${applicantId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    }
}