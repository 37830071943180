<template>
    <div class="col-span-12 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-6">
        <div class="flex items-start justify-between border-b border-stroke py-5 px-6 dark:border-strokedark">
            <div>
                <h2 class="text-title-md2 font-bold text-black dark:text-white">Recent activity</h2>
            </div>
        </div>
            
        <div class="p-6">
            <div class="flex flex-col gap-7">
                <div v-for="item in activityData" class="relative z-1 flex gap-5.5">
                    <template v-if="item.event_type == 'company_registration'">
                        <div class="h-16 w-full max-w-16 rounded-full border-[3px] border-stroke dark:border-strokedark flex items-center justify-center bg-meta-2 text-sky-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" /></svg>
                        </div>

                        <div>
                            <p class="text-black dark:text-white">
                                <span class="font-medium">New company registered</span>
                            </p>
                            <span class="mt-1 block text-sm">{{ formattedPostedDate(item.event_time) }}</span>

                            <p class="mt-2.5 text-black dark:text-white"><span class="font-medium">{{ item.company_name }}</span> has register in the <span class="font-medium">{{ item.industry }} industry!</span></p>
                        </div>
                    </template>

                    <template v-else-if="item.event_type == 'contractor_registration'">
                        <div class="h-16 w-full max-w-16 rounded-full border-[3px] border-stroke dark:border-strokedark items-center justify-center">
                            <img class="rounded-full object-cover border" :src="getImage(item.photo_hash)">
                        </div>

                        <div>
                            <p class="text-black dark:text-white">
                                <span class="font-medium">New contractor registered</span>
                            </p>
                            <span class="mt-1 block text-sm">{{ formattedPostedDate(item.event_time) }}</span>

                            <p class="mt-2.5 text-black dark:text-white"><span class="font-medium">{{ item.contractor_name }}</span> has registered as a <span class="font-medium">{{ item.industry}} / {{ item.role }}</span></p>
                        </div>
                    </template>

                    <template v-if="item.event_type == 'job_post'">
                        <div class="h-16 w-full max-w-16 rounded-full border-[3px] border-stroke dark:border-strokedark flex items-center justify-center bg-meta-2 text-sky-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" /></svg>
                        </div>

                        <div>
                            <p class="text-black dark:text-white">
                                <span class="font-medium">New job post</span>
                            </p>
                            <span class="mt-1 block text-sm">{{ formattedPostedDate(item.event_time) }}</span>

                            <p class="mt-2.5 text-black dark:text-white"><span class="font-medium">{{ item.company_name }}</span> has posted job <span class="font-medium">{{ item.job_title }} (#{{ item.job_slug }})</span> into <span class="font-medium">{{ item.industry }} / {{ item.role }}</span></p>
                        </div>
                    </template>

                    <template v-else-if="item.event_type == 'job_apply'">
                        <div class="h-16 w-full max-w-16 rounded-full border-[3px] border-stroke dark:border-strokedark items-center justify-center">
                            <img class="rounded-full object-cover border" :src="getImage(item.photo_hash)">
                        </div>

                        <div>
                            <p class="text-black dark:text-white">
                                <span class="font-medium">New job application</span>
                            </p>
                            <span class="mt-1 block text-sm">{{ formattedPostedDate(item.event_time) }}</span>

                            <p class="mt-2.5 text-black dark:text-white"><span class="font-medium">{{ item.contractor_name }}</span> has applied to the job <span class="font-medium">{{ item.job_title }} (#{{ item.job_slug }})</span> posted by <span class="font-medium">{{ item.company_name }}</span> in <span class="font-medium">{{ item.industry }} / {{ item.role }}</span></p>
                        </div>
                    </template>
                            
                    <span class="absolute left-8 -z-1 block h-[300%] w-[1px] border-l border-dashed border-stroke dark:border-strokedark"></span>
                </div>

                
            </div>
        </div>
    </div>
</template>

<script>
import genericService from '@/services/marketplace/generic.service'

export default {
    name: 'AdminActivity',
    props: ['activityData'],
    methods: {
        getImage(hash) {
          return genericService.getProfilePictureLink(hash)
        },
        formattedPostedDate(creationDateString) {
            const creationDate = new Date(creationDateString);
            const now = new Date();
            const diffInMs = now - creationDate;
            const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert ms to hours

            if (diffInHours < 24) {
                return `Posted ${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
            }

            const diffInDays = Math.floor(diffInHours / 24);
            if (diffInDays === 1) {
                return "Posted 1 day ago";
            }

            return `Posted ${diffInDays} days ago`;
        },
    }
}
</script>