<template>
    <router-link :to="resolveLink">
        <div class="relative flex flex-col border border-2 border-gray-300 bg-white rounded-lg p-5 w-full cursor-pointer hover:border-sky-300">
        <!-- Ribbon -->
        <div v-if="vAppDate" class="absolute top-0 right-0 bg-sky-300 text-white text-xs font-semibold py-1 px-3 rounded-bl-lg rounded-tr-md">
            Applied
        </div>
    
        <!-- Job Details -->
        <p class="text-xl font-semibold">{{ vTitle }}</p>
        <p class="text-lg">{{ vCompany }}</p>
        <div class="flex justify-between mt-5">
            <p class="">{{ vLocation }}</p>
            <p v-if="vRateCategory && vRateCategory !== 'custom_rate'">
                {{ vRateCategory.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()).replace(/\s*Rate\s*/gi, '').trim() }} Rate
            </p>
            <p v-else>${{ vRate }} / {{ vRateType }}</p>
        </div>
        <div class="flex justify-between">
            <p>{{ vIndustry }} / {{ vRole }}</p>
            <p>{{ vStartDate }}</p>
        </div>

        <div class="flex justify-between items-center mt-3 mb-0">
            <p class="text-xs text-gray-400">{{ formattedPostDate }}</p>
            <p v-if="vAppDate" class="text-xs text-gray-400">{{ formattedApplicationDate }}</p>
        </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'JobCard',
    props: ['vJobId', 'vSlug', 'vTitle', 'vCompany', 'vLocation', 'vIndustry', 'vRole', 'vDate', 'vAppDate', 'vRateCategory', 'vRate', 'vRateType', 'vStartDate'],
    computed: {
        formattedPostDate() {
            return this.formatDate(this.vDate, "Posted");
        },
        formattedApplicationDate() {
            return this.formatDate(this.vAppDate, "Applied");
        },
        resolveLink() {
            if (!this.vSlug) {
                return { name: 'ContractorViewJob', params: { jobId: '#' } };
            }

            return { name: 'ContractorViewJob', params: { jobId: this.vSlug } };
        }
    },
    methods: {
        formatDate(date, label) {
            if (!date) return `${label} unknown`; // Handle invalid or missing date

            // Treat the input date as UTC explicitly
            const creationDate = new Date(`${date}Z`); // Adding 'Z' ensures it's parsed as UTC
            if (isNaN(creationDate)) return `${label} invalid date`; // Handle invalid date

            const now = new Date();
            const diffInMs = now - creationDate;

            const diffInSeconds = Math.floor(diffInMs / 1000);
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            const diffInHours = Math.floor(diffInMinutes / 60);
            const diffInDays = Math.floor(diffInHours / 24);

            if (diffInMinutes < 60) {
                return `${label} ${diffInMinutes} minute${diffInMinutes !== 1 ? "s" : ""} ago`;
            }

            if (diffInHours < 24) {
                return `${label} ${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
            }

            if (diffInDays < 7) {
                return `${label} ${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
            }

            // Format the date manually to dd/mm/yyyy
            const day = String(creationDate.getUTCDate()).padStart(2, "0");
            const month = String(creationDate.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
            const year = creationDate.getUTCFullYear();

            return `${label} on ${day}/${month}/${year}`;
        },
    }
}
</script>